<script setup lang="ts">
useHeadCommon()
</script>

<template>
  <div class="layout layout-default">
    <AccessibilityQuickLinks />
    <slot name="header"> <Header /></slot>
    <main id="main-content" class="main">
      <slot />
    </main>
    <slot name="footer"> <Footer /> </slot>
  </div>
</template>
